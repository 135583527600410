import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Container from '..'
import { AppConfig } from '../../config/Appconfig'

function Settings() {
    const [dashboard, setDashboard] = useState({
        states: 0,
        districts: 0,
        blocks: 0,
        panchayats: 0
    })

    useEffect(() => {
        getDetails()
    }, [])

    const getDetails = () => {
        let url = AppConfig.API_URL + 'locations'
        axios.get(url).then(result => {
            let resp = result.data;
            console.log(resp)
            if (resp.success) {
                const item = resp.data;
                setDashboard(item)
            }
        })
    }


    return (
        <Container>
            <div className='page-header'>
                <h5>Settings</h5>
            </div>
            <div className='row g-2 mb-3'>
                <div className='col-6 col-md-3'>
                    <div className='cardbox border py-3 text-center'>
                        <div>STATES</div>
                        <h6 className='m-0 fs-2'>{dashboard.states}</h6>
                    </div>
                </div>
                <div className='col-6 col-md-3'>
                    <div className='cardbox border py-3 text-center'>
                        <div>DISTRICTS</div>
                        <h6 className='m-0 fs-2'>{dashboard.districts}</h6>
                    </div>
                </div>
                <div className='col-6 col-md-3'>
                    <div className='cardbox border py-3 text-center'>
                        <div>BLOCKS</div>
                        <h6 className='m-0 fs-2'>{dashboard.blocks}</h6>
                    </div>
                </div>
                <div className='col-6 col-md-3'>
                    <div className='cardbox border py-3 text-center'>
                        <div>VILLAGES</div>
                        <h6 className='m-0 fs-2'>{dashboard.panchayats}</h6>
                    </div>
                </div>
            </div>
            <Outlet />
        </Container>
    )
}

export default Settings
import { Login } from '@mui/icons-material';
import { Alert, Button, Paper, Switch } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Container from '..'
import Loader from '../../components/Loader';
import { apiCall, AppConfig } from '../../config/Appconfig';
import { Usercontext } from '../../config/Usercontext';

function MemberDetails() {
    const params = useParams();
    const navigate = useNavigate()
    const { id, name } = params;
    const [loader, setLoader] = useState(true)
    const [user, setUser] = useState(null)
    const { setAlert } = useContext(Usercontext)
    const [notice, setNotice] = useState({
        type: '',
        message: ''
    })

    useEffect(() => {
        if (id == undefined) {
            navigate('/members')
        }
        getInfo()
    }, [id])

    const getInfo = () => {
        let url = AppConfig.API_URL + 'get-user-info'
        axios.post(url, { user_id: id }).then(result => {
            let resp = result.data;
            if (resp.success) {
                console.log(resp.data);
                setUser(resp.data)
            } else {
                setAlert({ variant: 'danger', message: resp.message })
            }
            setLoader(false)
        }).catch(er => console.log('member-details', er))

    }

    const updateForm = () => {
        apiCall('application-update', { user_id: id, status: user.status }).then(result => {
            if (result.success) {
                setNotice({ type: 'success', message: result.message })
            } else {
                setNotice({ type: 'error', message: result.message })
            }
        })
    }

    const updateUser = () => {
        setUser({ ...user, mobile_verified: !user.mobile_verified })
        apiCall('user-update', { user_id: id, field: 'mobile_verified', value: !user.mobile_verified }).then(result => {
            if (result.success) {
                setNotice({ type: 'success', message: result.message })
            } else {
                setNotice({ type: 'error', message: result.message })
            }
        })
    }

    if (loader) {
        return (
            <Container>
                <Loader />
            </Container>
        )
    }

    if (user == null) {
        return (
            <Container>
                <h3>Member Not Found</h3>
            </Container>
        )
    }

    return (
        <Container>
            <div className='container'>
                <h3>{user.fullname}</h3>
                {notice.message.length > 0 && <Alert severity={notice.type}>{notice.message}</Alert>}
                <div className='row'>
                    <div className='col-sm-5'>
                        <Paper sx={{ padding: 2 }}>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td>Application Id</td>
                                        <td>#{user.id}</td>
                                    </tr>
                                    <tr>
                                        <td>Username</td>
                                        <td>{user.username}</td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>{user.full_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile</td>
                                        <td>{user.mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>Email Id</td>
                                        <td>{user.email_id}</td>
                                    </tr>
                                    <tr>
                                        <td>Aadhar Number</td>
                                        <td>{user.aadhar_no}</td>
                                    </tr>
                                    <tr>
                                        <td>PAN Number</td>
                                        <td>{user.pan.toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <td>Donation Amount</td>
                                        <td>{user.donation}</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Verified</td>
                                        <td>
                                            <Switch
                                                onChange={ev => updateUser()}
                                                size="small"
                                                value={1}
                                                checked={user.mobile_verified == 1}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Created</td>
                                        <td>{user.created}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Link to="/members" className="btn btn-primary">Go Back</Link>
                        </Paper>
                    </div>
                    <div className='col-sm-7'>
                        <Paper sx={{ p: 1 }}>
                            <table className='table bg-light border'>
                                <tbody>
                                    <tr>
                                        <td>Designation Applied</td>
                                        <td>{user.position.toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <td>Location</td>
                                        <td>
                                            {user.position == 'wto' && <>
                                                <div>Village: {user.location.village} <br />
                                                    Block: {user.location.block} <br />
                                                    Dist.: {user.location.dist} <br />
                                                    State: {user.location.state} <br />
                                                    Ward: {user.location.ward}
                                                </div>
                                            </>}
                                            {user.position == 'pto' && <>
                                                <div>Village: {user.location.village} <br />
                                                    Block: {user.location.block} <br />
                                                    Dist.: {user.location.dist} <br />
                                                    State: {user.location.state}
                                                </div>
                                            </>}
                                            {user.position == 'bto' && <>
                                                <div>
                                                    Block: {user.location.block} <br />
                                                    Dist.: {user.location.dist} <br />
                                                    State: {user.location.state}
                                                </div>
                                            </>}
                                            {user.position == 'dto' && <>
                                                <div>
                                                    Dist.: {user.location.dist} <br />
                                                    State: {user.location.state}
                                                </div>
                                            </>}
                                            {user.position == 'sto' && <>
                                                <div>
                                                    State: {user.location.state}
                                                </div>
                                            </>}
                                            {user.position == 'chto' && <>
                                                <div>
                                                    Country: {user.location}
                                                </div>
                                            </>}

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='table table-striped'>
                                <tbody>
                                    <tr>
                                        <td>Aadhar Front</td>
                                        <td>{user.aadhar_front != '' && <img src={user.aadhar_front_url} width={80} />}</td>
                                    </tr>
                                    <tr>
                                        <td>Aadhar Back</td>
                                        <td>{user.aadhar_back != '' && <img src={user.aadhar_back_url} width={80} />}</td>
                                    </tr>
                                    <tr>
                                        <td>PAN</td>
                                        <td>{user.pan != '' && <img src={user.pan_url} width={80} />}</td>
                                    </tr>
                                    <tr>
                                        <td>Photo</td>
                                        <td>{user.photo != '' && <img src={user.photo_url} width={80} />}</td>
                                    </tr>
                                    <tr>
                                        <td>Sahmati Path</td>
                                        <td>{user.doc1 != '' && <img src={user.doc1_url} width={80} />}</td>
                                    </tr>
                                    <tr>
                                        <td>Team Officer Form</td>
                                        <td>{user.doc2 != '' && <img src={user.doc2_url} width={80} />}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </Paper>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default MemberDetails
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Usercontext } from '../config/Usercontext';

function Alert({ variant, message }) {
    const { alert, setAlert } = useContext(Usercontext)

    useEffect(() => {
        return () => setAlert({ message: '', variant: '' })
    }, [])

    if (message.length > 0) {
        return (
            <div className={'alert alert-' + variant}>{message}</div>
        )
    } else if (alert.message.length > 0) {
        return (
            <div className={'alert alert-' + alert.variant}>{alert.message}</div>
        )
    }
    return null;
}

Alert.propTypes = {
    variant: PropTypes.string,
    message: PropTypes.string
}

Alert.defaultProps = {
    variant: '',
    message: ''
}

export default Alert
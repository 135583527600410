import { AddCircleOutline } from '@mui/icons-material'
import { Button, Container, Input, Modal, Paper, Box, Alert, CircularProgress, Pagination } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppConfig } from '../../config/Appconfig'

function Villages() {
    const [alert, setAlert] = useState({ visible: false, message: '', variant: 'error' })
    const [action, setAction] = useState('show')
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [blocks, setBlocks] = useState([])
    const [distlist, setDistlist] = useState([])
    const [areas, setAreas] = useState([])
    const [total_areas, setTotal_areas] = useState(0)
    const [saving, setSaving] = useState(false)
    const [form, setForm] = useState({
        id: 0,
        state_id: '',
        dist_id: '',
        block_id: '',
        area_name: '',
        seats: '',
        wallet: 0
    })

    const [state_filter, setState_filter] = useState(0)
    const [text_filter, setText_filter] = useState('');
    const [page, setPage] = useState(1)

    useEffect(() => {
        loadStates()
        loadDistrict()
    }, [])

    useEffect(() => {
        loadBlocks()
        loadArea()
    }, [action, page, state_filter, text_filter])

    const actionAdd = () => {
        setAction('add')
    }

    const loadStates = () => {
        let url = AppConfig.API_URL + 'states'
        axios.post(url, { action: 'show' }).then(result => {
            let resp = result.data;
            if (resp.success)
                setStates(resp.data)
        })
    }

    const loadDistrict = () => {
        let url = AppConfig.API_URL + 'districts'
        axios.post(url, { action: 'show' }).then(result => {
            let resp = result.data;
            if (resp.success)
                setDistricts(resp.data)
        })
    }

    const loadBlocks = () => {
        let url = AppConfig.API_URL + 'blocks'
        axios.post(url, { action: 'show', dist_id: form.dist_id }).then(result => {
            let resp = result.data;
            if (resp.success)
                setBlocks(resp.data)
        })
    }

    const loadArea = () => {
        console.log(page)
        let url = AppConfig.API_URL + 'villages'
        axios.post(url, { action: 'show', state_id: state_filter, q: text_filter, page: page }).then(result => {
            let resp = result.data
            if (resp.success) {
                setAreas(resp.data.items)
                setTotal_areas(resp.data.total)
            }
        })
    }

    const handleSave = () => {
        if (form.state_id == '') {
            setAlert({ visible: true, message: 'Select state', variant: 'error' })
            return;
        }
        if (form.dist_id == '') {
            setAlert({ visible: true, message: 'Select district', variant: 'error' })
            return;
        }
        if (form.block_name == '') {
            setAlert({ visible: true, message: 'Enter block name', variant: 'error' })
            return;
        }
        if (form.seats == '') {
            setAlert({ visible: true, message: 'Enter seats', variant: 'error' })
            return
        }
        setSaving(true)
        let url = AppConfig.API_URL + 'panchayat'
        form.action = 'save';
        axios.post(url, form).then(result => {
            let resp = result.data;
            if (resp.success) {
                setForm({ ...form, area_name: '', seats: '', wallet: '' })
                setAlert({ ...alert, variant: 'success', message: resp.message, visible: true })
            } else {
                setAlert({ ...alert, variant: 'error', message: resp.message, visible: true })
            }
        }).catch(err => console.log(err.message))
            .finally(() => setSaving(false))
    }

    const changeState = (state_id) => {
        const dist = []
        districts.forEach(item => {
            if (item.state_id == state_id) dist.push(item)
        })
        setDistlist(dist)
        setForm({ ...form, state_id: state_id })
    }

    const changeDistrict = (dist_id) => {
        setBlocks([])
        let url = AppConfig.API_URL + 'blocks'
        axios.post(url, { dist_id: dist_id, action: 'show' }).then(result => {
            let resp = result.data;
            if (resp.success) {
                setBlocks(resp.data)
            }
        })
        setForm({ ...form, dist_id: dist_id })
    }

    const delDist = (id) => {
        if (window.confirm("are you sure to delete?")) {
            let url = AppConfig.API_URL + 'delete-area'
            axios.post(url, { id: id }).then(result => {
                let resp = result.data;
                if (resp.success)
                    loadArea()
            })
        }
    }

    const setStateFilter = (st) => {
        setState_filter(st)
    }

    const setQueryFilter = (chr) => {
        setText_filter(chr)
    }

    if (action == 'add') {
        return (
            <Box>
                <Container maxWidth="sm">
                    <h6>Add Panchayat</h6>
                    {alert.visible && <Alert severity={alert.variant}>{alert.message}</Alert>}
                    <Paper className="p-3">
                        <label>State</label>
                        <select className='form-select mb-3'
                            value={form.state_id}
                            onChange={ev => changeState(ev.target.value)}
                        >
                            <option value=''>Select State</option>
                            {states.map(item => (
                                <option key={'st' + item.id} value={item.id}>{item.state_name}</option>
                            ))}
                        </select>
                        <select className='form-select mb-3'
                            value={form.dist_id}
                            onChange={ev => changeDistrict(ev.target.value)}
                        >
                            <option value=''>Select District</option>
                            {distlist.map(item => (
                                <option key={'dist' + item.id} value={item.id}>{item.dist_name}</option>
                            ))}
                        </select>
                        <select className='form-select mb-3'
                            value={form.block_id}
                            onChange={ev => setForm({ ...form, block_id: ev.target.value })}
                        >
                            <option value=''>Select Block</option>
                            {blocks.map(item => (
                                <option key={'blk' + item.id} value={item.id}>{item.block_name}</option>
                            ))}
                        </select>
                        <label>Panchayat name</label>
                        <input
                            onChange={ev => setForm({ ...form, area_name: ev.target.value })}
                            value={form.area_name}
                            placeholder='Panchayat name' className='form-control mb-3' />

                        <div className='row'>
                            <div className='col-sm-6'>
                                <label>Seats</label>
                                <input
                                    onChange={ev => setForm({ ...form, seats: ev.target.value })}
                                    value={form.seats}
                                    placeholder='Seats' className='form-control mb-3' />
                            </div>
                            <div className='col-sm-6'>
                                <label>Wallet</label>
                                <input
                                    onChange={ev => setForm({ ...form, wallet: ev.target.value })}
                                    value={form.wallet}
                                    placeholder='Wallet Balance' className='form-control mb-3' />
                            </div>
                        </div>
                        <Button
                            disabled={saving}
                            onClick={handleSave} sx={{ mr: 1 }}>
                            {saving ? 'SAVING...' : 'SAVE'}
                        </Button>
                        <Button color='secondary' onClick={() => {
                            setAction('show')
                            setSaving(false)
                        }} >Cancel</Button>
                    </Paper>
                </Container>
            </Box>
        )
    }

    return (
        <>
            <div className='page-header'>
                <h5 className='m-0'>Village List : Page {page}</h5>
                <div className='d-flex justify-content-between align-items-center'>
                    <select
                        onChange={ev => setStateFilter(ev.target.value)}
                        className='form-select me-2'>
                        <option value="0">Select State</option>
                        {states.map((item) => <option key={'dt' + item.state_code} value={item.state_code}>{item.state_name}</option>)}
                    </select>
                    <input type='search'
                        onChange={ev => setQueryFilter(ev.target.value)}
                        className='form-control' placeholder='Search by name or code' />
                </div>
            </div>
            <div className='cardbox pb-3'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>Area name (Code)</th>
                            <th>Block name</th>
                            <th>District name</th>
                            <th>State name</th>
                            <th>Seats</th>
                            <th>Members</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {areas.length > 0 && <>
                            {areas.map((item, sl) => (
                                <tr key={'stlist' + sl}>
                                    <td>{((page - 1) * 20) + (sl + 1)}</td>
                                    <td>{item.area_name} ({item.area_code})</td>
                                    <td>{item.dist_name}</td>
                                    <td>{item.block_name}</td>
                                    <td>{item.state_name}</td>
                                    <td>{item.seats}</td>
                                    <td>
                                        <Link to={'users'} className="btn btn-xs btn-primary">10</Link>
                                    </td>
                                    <td>
                                        <button onClick={() => delDist(item.id)} className='btn ms-1 btn-danger btn-xs'>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </>}
                    </tbody>
                </table>
                <Pagination shape='rounded'
                    onChange={(ev, pg) => setPage(pg)}
                    count={Math.ceil(total_areas / 20)} variant="outlined" color="primary" />
            </div>
        </>
    )
}

export default Villages
import { Add, ArrowCircleUp, CheckOutlined, ChevronLeft, ChevronRight, Flight, KeyboardCapslock, SupportAgentOutlined } from '@mui/icons-material'
import { Avatar, Button, Chip, Divider, IconButton, LinearProgress, Paper } from '@mui/material'
import { blue, green, orange, pink, purple } from '@mui/material/colors';
import React, { useContext, useEffect, useState } from 'react'
import Container from '..'
import { apiCall, AppConfig } from '../../config/Appconfig'
import { Usercontext } from '../../config/Usercontext'
import user from '../../assets/user.png'
import grph from '../../assets/grph.png'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const { login } = useContext(Usercontext)
    const [dashboard, setDashboard] = useState({
        total_users: 0,
        kyc_completed: 0,
        total_balance: 0,
        total_saving: 0,
        complains: 0,
        complains_completed: 0,
        hhp_in: 0,
        hhp_out: 0,
        hhp_net_cost: 0,
        hhp_npa: 0,
        earning_donate: 0,
        earning_cost: 0
    })
    const [tabs, setTabs] = useState([])
    const [users, setUsers] = useState([])
    const [items, setItems] = useState([])
    const [mobile, setMobile] = useState('')

    const navigate = useNavigate();

    useEffect(() => {
        loadSummary();
        loadNewMembers();
        loadTransactions();
    }, [login.id])

    const loadSummary = async () => {
        apiCall('dashboard').then(result => {
            console.log(result);
            if (result.success) {
                setDashboard(result.data);
            }
        })
    }
    const loadTransactions = async () => {
        apiCall('dashboard-recent-transactions').then(result => {
            if (result.success) {
                setTabs(result.data.tabs)
                setItems(result.data.items);
            }
        })
    }
    const loadNewMembers = async () => {
        apiCall('dashboard-new-members').then(result => {
            if (result.success) {
                setUsers(result.data)
            }
        })
    }

    const doSearch = () => {
        if ((mobile + '').length == 10) {
            alert('Enter mobile');
            return
        }
        navigate('/search/?q=' + mobile)
    }

    return (
        <Container>
            <h6>Dashboard</h6>
            <div className='row g-2'>
                <div className='col-sm-3'>
                    <div className='mb-2'>
                        <div className='input-group'>
                            <input
                                onChange={ev => setMobile(ev.target.value)}
                                placeholder='Enter mobile no'
                                maxLength={10}
                                className='form-control input-sm' />
                            <Button onClick={doSearch} >GO</Button>
                        </div>
                    </div>
                    <div className='color-pink widget'>
                        <div className='widget-body'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <div>
                                    <h6 className='small m-0'><b>KYC Status</b></h6>
                                </div>
                                <div className='next-prev'>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronLeft fontSize='14px' />
                                    </IconButton>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronRight fontSize='14px' />
                                    </IconButton>
                                </div>
                            </div>
                            <div className='py-2 d-flex align-items-center'>
                                <div>
                                    <Avatar sx={{ bgcolor: blue[500] }} >
                                        <KeyboardCapslock />
                                    </Avatar>
                                </div>
                                <div className='ps-3'>
                                    <div>{parseInt(dashboard.kyc_completed / dashboard.total_users * 100)}% KYC Pending</div>
                                    <LinearProgress variant="determinate" value={parseFloat(dashboard.kyc_completed / dashboard.total_users * 100)} />
                                    <div className='small text-muted'>{dashboard?.kyc_completed} out of {dashboard.total_users}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='color-parrot widget'>
                        <div className='widget-body'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <div>
                                    <h6 className='small m-0'><b>Status Completed</b></h6>
                                </div>
                                <div className='next-prev'>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronLeft fontSize='14px' />
                                    </IconButton>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronRight fontSize='14px' />
                                    </IconButton>
                                </div>
                            </div>
                            <div className='py-2 d-flex align-items-center'>
                                <div>
                                    <Avatar sx={{ bgcolor: green[500] }} >
                                        <CheckOutlined />
                                    </Avatar>
                                </div>
                                <div className='ps-3'>
                                    <div>62% KYC Pending</div>
                                    <LinearProgress variant="determinate" value={50} />
                                    <div className='small text-muted'>{dashboard.complains_completed} out of {dashboard.complains}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='color-pink1 widget'>
                        <div className='widget-body'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <div>
                                    <h6 className='small m-0'><b>Complain Pending</b></h6>
                                </div>
                                <div className='next-prev'>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronLeft fontSize='14px' />
                                    </IconButton>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronRight fontSize='14px' />
                                    </IconButton>
                                </div>
                            </div>
                            <div className='py-2 d-flex align-items-center'>
                                <div>
                                    <Avatar sx={{ bgcolor: purple[500] }} >
                                        <SupportAgentOutlined />
                                    </Avatar>
                                </div>
                                <div className='ps-3'>
                                    <div>62% KYC Pending</div>
                                    <LinearProgress variant="determinate" value={50} />
                                    <div className='small text-muted'>{dashboard.complains_completed} out of {dashboard.complains}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='color-orange widget'>
                        <div className='widget-body'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <div>
                                    <h6 className='small m-0'><b>Approval Pending</b></h6>
                                </div>
                                <div className='next-prev'>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronLeft fontSize='14px' />
                                    </IconButton>
                                    <IconButton size="small" sx={{ p: 0 }}>
                                        <ChevronRight fontSize='14px' />
                                    </IconButton>
                                </div>
                            </div>
                            <div className='py-2 d-flex align-items-center'>
                                <div>
                                    <Avatar sx={{ bgcolor: orange[500] }} >
                                        <SupportAgentOutlined />
                                    </Avatar>
                                </div>
                                <div className='ps-3'>
                                    <div>62% KYC Pending</div>
                                    <LinearProgress variant="determinate" value={50} />
                                    <div className='small text-muted'>{dashboard.complains_completed} out of {dashboard.complains}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-9'>
                    <div className='row g-2 mb-2'>
                        <div className='col-sm-8'>
                            <div className='row g-2'>
                                <div className='col-sm-6'>
                                    <div className='color-info widget'>
                                        <div className='widget-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <h6 className='small m-0'>HHP Available Balance</h6>
                                                </div>
                                                <div className='next-prev'>
                                                    <IconButton size="small" sx={{ p: 0, color: green[500] }}>
                                                        <ArrowCircleUp fontSize='14px' />
                                                    </IconButton>
                                                    <span className='text-success'>3.65%</span>
                                                </div>
                                            </div>
                                            <div className='py-3 text-center text-success'>
                                                <h4 className='m-0'>Rs {dashboard.total_balance}</h4>
                                            </div>
                                            <div className='px-3'>
                                                <div className='d-flex py-2'>
                                                    <div className='text-primary flex-grow-1 text-center'>
                                                        <div style={{ fontSize: 9, color: blue[800] }}>HHP_IN</div>
                                                        {AppConfig.INR_SYMBOL} {dashboard.hhp_in}
                                                    </div>
                                                    <div>
                                                        <Divider sx={{ borderColor: '#111' }} orientation='vertical' />
                                                    </div>
                                                    <div className='text-primary flex-grow-1 text-center'>
                                                        <div style={{ fontSize: 9, color: blue[800] }}>HHP_NPA</div>
                                                        {AppConfig.INR_SYMBOL} {dashboard.hhp_out}
                                                    </div>
                                                </div>
                                                <Divider sx={{ borderColor: '#111' }} />
                                                <div className='d-flex py-2'>
                                                    <div className='text-primary flex-grow-1 text-center'>
                                                        <div style={{ fontSize: 9, color: blue[800] }}>HHP_Out</div>
                                                        {AppConfig.INR_SYMBOL} {dashboard.hhp_npa}
                                                    </div>
                                                    <div>
                                                        <Divider sx={{ borderColor: '#111' }} orientation='vertical' />
                                                    </div>
                                                    <div className='text-danger flex-grow-1 text-center'>
                                                        <div style={{ fontSize: 9, color: blue[800] }}>HHP_Network Cost</div>
                                                        {AppConfig.INR_SYMBOL} {dashboard.hhp_in * 0.11}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='color-info widget'>
                                        <div className='widget-body'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <h6 className='small m-0'>Total Saving</h6>
                                                </div>
                                                <div className='next-prev'>
                                                    <IconButton size="small" sx={{ p: 0, color: green[500] }}>
                                                        <ArrowCircleUp fontSize='14px' />
                                                    </IconButton>
                                                    <span className='text-success'>3.65%</span>
                                                </div>
                                            </div>
                                            <div className='py-3 text-center text-danger'>
                                                <h4 className='m-0'>Rs {dashboard.total_saving}</h4>
                                            </div>
                                            <div className='px-3'>
                                                <div className='d-flex py-2'>
                                                    <div className='text-primary flex-grow-1 text-end'>
                                                        <div className='small text-dark'>Sending Donation</div>
                                                        {AppConfig.INR_SYMBOL} {dashboard.earning_donate}
                                                    </div>
                                                    <div className='px-2'>
                                                        <Divider sx={{ borderColor: '#111' }} orientation='vertical' />
                                                    </div>
                                                    <div className='text-primary flex-grow-1 text-start'>
                                                        <div className='small text-dark'>Sending Cost</div>
                                                        {AppConfig.INR_SYMBOL} {dashboard.earning_cost}
                                                    </div>
                                                </div>
                                                <img alt='agb' src={grph} height={20} style={{ height: 45, marginLeft: 'auto', marginRight: 'auto' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='graph-wrapper'>
                                <div className='color-dark widget text-white'>
                                    <div className='widget-body'>
                                        <Bar
                                            datasetIdKey='id'
                                            data={{
                                                labels: ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov'],
                                                datasets: [
                                                    {
                                                        id: 1,
                                                        label: 'Expanse',
                                                        data: [5, 8, 1, 4, 5, 6],
                                                    },
                                                    {
                                                        id: 2,
                                                        label: 'Income',
                                                        data: [3, 8, 2, 7, 8, 9],
                                                    },

                                                ]
                                            }}
                                            options={{
                                                scales: {
                                                    y: {
                                                        beginAtZero: true
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row g-2'>
                                <div className='col-sm-6'>
                                    <div className='color-orange1 widget'>
                                        <div className='widget-body'>
                                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                                <div>
                                                    <h6 className='small m-0'><b>Goal</b></h6>
                                                    <div className='small'>Summer Vacation</div>
                                                </div>
                                                <div className='next-prev'>
                                                    <IconButton size="small" sx={{ p: 0 }}>
                                                        <ChevronLeft fontSize='14px' />
                                                    </IconButton>
                                                    <IconButton size="small" sx={{ p: 0 }}>
                                                        <ChevronRight fontSize='14px' />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <div className='py-2 d-flex align-items-center'>
                                                <div>
                                                    <Avatar sx={{ bgcolor: green[500] }} >
                                                        <Flight />
                                                    </Avatar>
                                                </div>
                                                <div className='ps-3'>
                                                    <div>62% KYC Pending</div>
                                                    <LinearProgress variant="determinate" value={50} />
                                                    <div className='small text-muted'>500 out of 1100</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='color-orange1 widget'>
                                        <div className='widget-body'>
                                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                                <div>
                                                    <h6 className='small m-0'><b>Sending Overflow</b></h6>
                                                </div>
                                            </div>
                                            <div className='py-0'>
                                                <div className='mb-2 d-flex align-items-center'>
                                                    <div className='w-75'>
                                                        <LinearProgress variant="determinate" value={100} />
                                                    </div>
                                                    <div className='w-25 small ps-2'>100% Done</div>
                                                </div>
                                                <div className='mb-2 d-flex align-items-center'>
                                                    <div className='w-75'>
                                                        <LinearProgress variant="determinate" value={50} />
                                                    </div>
                                                    <div className='w-25 small ps-2'>50% Done</div>
                                                </div>
                                                <div className='mb-2 d-flex align-items-center'>
                                                    <div className='w-75'>
                                                        <LinearProgress variant="determinate" value={30} />
                                                    </div>
                                                    <div className='w-25 small ps-2'>30% Done</div>
                                                </div>
                                                <div className='mb-2 d-flex align-items-center'>
                                                    <div className='w-75'>
                                                        <LinearProgress variant="determinate" value={10} />
                                                    </div>
                                                    <div className='w-25 small ps-2'>10% Done</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='home-transactions'>
                                <h5 className='h6'>Recent Transactions</h5>
                                <div className='more-buttons mb-3 overflow-auto d-flex'>
                                    {tabs.map((tab) => <Chip className='me-1' key={'chip' + tab.value} size="small" clickable label={tab.label} />)}
                                </div>
                                <ul>
                                    {items.map(item => <li>
                                        <Avatar sx={{ fontSize: 14 }} size="small">KT</Avatar>
                                        <div className='content-body'>
                                            <h6>{item.title}</h6>
                                            <div className='small text-muted'>{item.sub_title}</div>
                                        </div>
                                        <div className={item.cr_dr == 'cr' ? 'amount text-success' : 'amount text-danger'}>
                                            <span className='inr'>{AppConfig.INR_SYMBOL} </span> {item.amount}
                                        </div>
                                    </li>)}

                                </ul>
                            </div>
                            <div className='graph-wrapper'>
                                <div className='color-dark widget text-white p-3'>
                                    <div className='d-flex justify-content-between align-items-center mb-4'>
                                        <h6 className='small m-0'>New Join</h6>
                                        <div className='next-prev'>
                                            <IconButton size="small" color="error" sx={{ p: 0, color: '#fff' }}>
                                                <ChevronLeft fontSize='14px' />
                                            </IconButton>
                                            <IconButton size="small" color="error" sx={{ p: 0, color: '#fff' }}>
                                                <ChevronRight fontSize='14px' />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className='user-items'>
                                        <ul>
                                            <li>
                                                <Avatar sx={{ bgcolor: pink[400] }}>
                                                    <Add />
                                                </Avatar>
                                                <div>Add New</div>
                                            </li>
                                            {users.map(item => <li onClick={() => navigate('members/details/' + item.id)} key={'user-' + item.id}>
                                                <Avatar src={user} />
                                                <div>{item.fullname}</div>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Paper sx={{ height: 140, bgcolor: blue[500] }}>

                    </Paper>
                </div>
            </div>
        </Container>
    )
}

export default Dashboard
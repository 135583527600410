import axios from "axios";

const isLive = true;
const TEST_API_URL = 'http://localhost/kosofapi/adminapi/call/';
const LIVE_API_URL = 'https://api.kosoftrust.com/adminapi/call/';

export const AppConfig = {
    API_URL: isLive ? LIVE_API_URL : TEST_API_URL,
    // LOGIN_URL: 'http://localhost/kosofapi/adminapi/login'
    LOGIN_URL: 'https://api.kosoftrust.com/adminapi/login',
    UPLOAD_URL: 'https://api.kosoftrust.com/writable/uploads/',
    INR_SYMBOL: '\u20B9',
}

export const apiCall = async (module, data = {}) => {
    try {
        const url = AppConfig.API_URL + module
        data.token = 'api_toke_key'
        const result = await axios.post(url, data);
        const resp = await result.data;
        return resp;
    } catch (er) {
        console.log('api-call-error', er, module, data)
    }
}
import { Avatar, Button, Divider, Paper } from '@mui/material'
import React from 'react'
import Header from '../../components/Header'
import user from '../../assets/user.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { apiCall } from '../../config/Appconfig'

function Search() {
    const [items, setItems] = useState([])
    const location = useLocation().search;
    const s = new URLSearchParams(location).get('q')
    const navigate = useNavigate()

    useEffect(() => {
        if (s !== null) {
            apiCall('dashboard-search', { q: s }).then(result => {
                if (result.success)
                    setItems(result.data)
            })
        }
    }, [s])

    return (
        <div className='dashboard'>
            <Header />
            <div className='container'>
                <div className='page-header pt-3'>
                    <h5>Search Result</h5>
                </div>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <div className='search-result'>
                        {items.map(item => <div key={item.id} className='py-3 d-flex align-items-center search-item'>
                            <Avatar src={user} />
                            <div className='ps-2 flex-grow-1'>
                                <div><b>{item.fullname}</b></div>
                                <div className='text-muted small'>+91-{item.mobile}</div>
                            </div>
                            <Button
                                onClick={() => navigate('/accounts/' + item.id)}
                                size="small">Details</Button>
                        </div>)}
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default Search
import { Alert, Button, Paper } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AppConfig } from '../../config/Appconfig'

function TotalSeats() {
    const [seats, setSeats] = useState({
        state_seat: 0,
        dist_seat: 0,
        block_seat: 0,
        area_seat: 0,
    })
    const [checked, setChecked] = useState(false)
    const [alert, setAlert] = useState({
        mode: '',
        message: ''
    })

    useEffect(() => {
        const loadData = () => {
            let url = AppConfig.API_URL + 'default-seats'
            axios.get(url).then(result => {
                let resp = result.data;
                if (resp.success) {
                    setSeats(resp.data)
                }
            })
        }
        loadData();
    }, [])

    const saveDetails = () => {
        let url = AppConfig.API_URL + 'save-seats'
        seats.setall = checked ? 1 : 0;
        axios.post(url, seats).then(result => {
            let resp = result.data;
            if (resp.success) {
                setAlert({ mode: 'success', message: resp.message })
            } else {
                setAlert({ mode: 'error', message: resp.message })
            }
        })
    }

    return (
        <>
            <div className='page-header'>
                <h5>Total Seats</h5>
            </div>
            <Paper className='p-3'>
                <div className='row'>
                    <div className='col-sm-6'>
                        {alert.message.length > 0 && <Alert severity={alert.mode}>{alert.message}</Alert>}
                        <div className='row align-items-center mb-3'>
                            <label className='col-sm-4 text-end'>State Total Seats</label>
                            <div className='col-sm-4'>
                                <input
                                    value={seats.state_seat}
                                    onChange={ev => setSeats({ ...seats, state_seat: ev.target.value })}
                                    className='form-control' />
                            </div>
                        </div>
                        <div className='row align-items-center mb-3'>
                            <label className='col-sm-4 text-end'>District Total Seats</label>
                            <div className='col-sm-4'>
                                <input
                                    value={seats.dist_seat}
                                    onChange={ev => setSeats({ ...seats, dist_seat: ev.target.value })}
                                    className='form-control' />
                            </div>
                        </div>
                        <div className='row align-items-center mb-3'>
                            <label className='col-sm-4 text-end'>Blocks Total Seats</label>
                            <div className='col-sm-4'>
                                <input
                                    value={seats.block_seat}
                                    onChange={ev => setSeats({ ...seats, block_seat: ev.target.value })}
                                    className='form-control' />
                            </div>
                        </div>
                        <div className='row align-items-center mb-3'>
                            <label className='col-sm-4 text-end'>Area Total Seats</label>
                            <div className='col-sm-4'>
                                <input
                                    value={seats.area_seat}
                                    onChange={ev => setSeats({ ...seats, area_seat: ev.target.value })}
                                    className='form-control' />
                            </div>
                        </div>
                        <div className='row align-items-center mb-3'>
                            <label className='col-sm-4 text-end'></label>
                            <div className='col-sm-4'>
                                <input
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                    type="checkbox" id='update' className='checkbox' value={1} />
                                <label htmlFor='update' className='ms-2'>Apply to all records</label>
                            </div>
                        </div>
                        <div className='row align-items-center mb-3'>
                            <label className='col-sm-4 text-end'></label>
                            <div className='col-sm-4'>
                                <Button
                                    onClick={saveDetails} className='btn btn-primary'>SAVE</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </>
    )
}

export default TotalSeats
import React from 'react'
import Container from '..'

function Masters() {
    return (
        <Container>
            <div className='page-header'>
                <h5>Utility Service Providers</h5>
            </div>
        </Container>
    )
}

export default Masters
import { AddCircleOutline } from '@mui/icons-material'
import { Button, Container, Input, Modal, Paper, Box, Alert, CircularProgress } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppConfig } from '../../config/Appconfig'

function Districts() {
    const [alert, setAlert] = useState({ visible: false, message: '', variant: 'error' })
    const [action, setAction] = useState('show')
    const [states, setStates] = useState([])
    const [items, setItems] = useState([])
    const [items1, setItems1] = useState([])
    const [saving, setSaving] = useState(false)
    const [form, setForm] = useState({
        id: 0,
        dist_name: '',
        state_id: '',
        seats: '',
        wallet: 0
    })

    const [search, setSearch] = useState('')
    const [state_id, setState_id] = useState(0)

    useEffect(() => {
        loadStates()
    }, [])

    useEffect(() => {
        loadDistrict()
    }, [action])

    const actionAdd = () => {
        setAction('add')
        setAlert({ ...alert, message: '' })
    }

    const loadStates = () => {
        let url = AppConfig.API_URL + 'states'
        axios.post(url, { action: 'show' }).then(result => {
            let resp = result.data;
            if (resp.success)
                setStates(resp.data)
        })
    }

    const loadDistrict = () => {
        let url = AppConfig.API_URL + 'districts'
        axios.post(url, { action: 'show' }).then(result => {
            let resp = result.data;
            if (resp.success) {
                setItems(resp.data)
                setItems1(resp.data);
            }
        })
    }

    const setEdit = (item) => {
        setForm(item)
        setAlert({ ...alert, message: '' })
        setAction('add')
    }

    const handleSave = () => {
        if (form.state_id == '') {
            setAlert({ visible: true, message: 'Select state', variant: 'error' })
            return;
        }
        if (form.dist_name == '') {
            setAlert({ visible: true, message: 'Enter district name', variant: 'error' })
            return;
        }
        if (form.seats == '') {
            setAlert({ visible: true, message: 'Enter seats', variant: 'error' })
            return
        }
        setSaving(true)
        let url = AppConfig.API_URL + 'districts'
        form.action = 'save'
        axios.post(url, form).then(result => {
            let resp = result.data;
            if (resp.success) {
                setForm({ ...form, dist_name: '', seats: '', wallet: '' })
                setAlert({ ...alert, variant: 'success', message: resp.message, visible: true })
            } else {
                setAlert({ ...alert, variant: 'error', message: resp.message, visible: true })
            }
        }).catch(err => console.log(err.message))
            .finally(() => setSaving(false))
    }

    const delDist = (id) => {
        if (window.confirm("are you sure to delete?")) {
            let url = AppConfig.API_URL + 'districts'
            axios.post(url, { id: id, action: 'delete' }).then(result => {
                let resp = result.data;
                if (resp.success)
                    loadDistrict()
            })
        }
    }

    const setStateFilter = (st) => {
        const tmplist = []
        items1.forEach(item => {
            if (item.state_id == st || st == 0) {
                tmplist.push(item)
            }
        })
        setItems(tmplist);
    }

    const setQueryFilter = (chr) => {
        let _arr = [];
        items1.forEach(item => {
            if (item.dist_name.toLowerCase().indexOf(chr) !== -1) {
                _arr.push(item);
            }
        })
        setItems(_arr)
    }

    if (action == 'add') {
        return (
            <Box>
                <Container maxWidth="sm">
                    <h6>Add District</h6>
                    {alert.message.length > 0 && <Alert severity={alert.variant}>{alert.message}</Alert>}
                    <Paper className="p-3">
                        <label>State</label>
                        <select className='form-select mb-3'
                            value={form.state_id}
                            onChange={ev => setForm({ ...form, state_id: ev.target.value })}
                        >
                            <option value=''>Select State</option>
                            {states.map(item => (
                                <option key={"st" + item.id} value={item.id}>{item.state_name}</option>
                            ))}
                        </select>
                        <label>District name</label>
                        <input
                            onChange={ev => setForm({ ...form, dist_name: ev.target.value })}
                            value={form.dist_name}
                            placeholder='District name' className='form-control mb-3' />
                        <div className='row'>
                            <div className='col-sm-6'>
                                <label>Seats</label>
                                <input
                                    onChange={ev => setForm({ ...form, seats: ev.target.value })}
                                    value={form.seats}
                                    placeholder='Seats' className='form-control mb-3' />
                            </div>
                            <div className='col-sm-6'>
                                <label>Wallets</label>
                                <input
                                    onChange={ev => setForm({ ...form, wallet: ev.target.value })}
                                    value={form.wallet}
                                    placeholder='Wallet' className='form-control mb-3' />
                            </div>
                        </div>
                        <Button
                            disabled={saving}
                            onClick={handleSave} sx={{ mr: 1 }}>
                            {saving ? 'SAVING...' : 'SAVE'}
                        </Button>
                        <Button color='secondary' onClick={() => {
                            setAction('show')
                            setSaving(false)
                        }} >Cancel</Button>
                    </Paper>
                </Container>
            </Box >
        )
    }

    return (
        <>
            <div className='page-header'>
                <h5 className='m-0'>District List</h5>
                <div className='d-flex justify-content-between align-items-center'>
                    <select
                        onChange={ev => setStateFilter(ev.target.value)}
                        className='form-select me-2'>
                        <option value="0">Select State</option>
                        {states.map((item) => <option key={'dt' + item.state_code} value={item.state_code}>{item.state_name}</option>)}
                    </select>
                    <input type='search'
                        onChange={ev => setQueryFilter(ev.target.value)}
                        className='form-control' placeholder='Search by name or code' />
                </div>
            </div>
            <div className='cardbox'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>District name (Code) </th>
                            <th>State name</th>
                            <th>Seats</th>
                            <th>Wallet</th>
                            <th>Members</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.length > 0 && <>
                            {items.map((item, sl) => (
                                <tr key={'stlist' + sl}>
                                    <td>{sl + 1}</td>
                                    <td>{item.dist_name} ({item.dist_code}) </td>
                                    <td>{item.state_name}</td>
                                    <td>{item.seats}</td>
                                    <td>{item.wallet}</td>
                                    <td>
                                        <Link to={'users'} className="btn btn-xs btn-primary">10</Link>
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => setEdit(item)}
                                            className="btn btn-xs btn-primary">Edit</button>
                                        <button onClick={() => delDist(item.id)} className='btn ms-1 btn-danger btn-xs'>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </>}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Districts
import React from 'react'
import loaderimage from '../assets/loader.svg'

function Loader() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flex: 1, minHeight: 200 }}>
            <img src={loaderimage} alt='Loader' width={140} height={80} />
        </div>
    )
}

export default Loader
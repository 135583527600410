import React from 'react'
import Container from '..'

function Reports() {
    return (
        <Container>
            <div className='page-header'>
                <h5>Reports</h5>
            </div>
        </Container>
    )
}

export default Reports
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom'
import Container from '..'

function Payments() {
    const params = useParams();
    const { tab } = params;
    console.log(tab)
    const [items, setItems] = useState([])
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        loadData();
    }, [])

    const loadData = async() => {

    }
    return (
        <Container>
            <div className='container'>
                <div className='page-header'>
                    <h5>Payments</h5>
                </div>
            </div>
        </Container>
    )
}

export default Payments
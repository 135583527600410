import React from 'react'
import { Link } from 'react-router-dom'
import Container from '..'

function Categories() {
    return (
        <Container>
            <div className='page-header'>
                <h5>Categories</h5>
                <Link to={'/categories/add'} className='btn btn-sm btn-primary'>
                    ADD NEW
                </Link>
            </div>
            <div className='cardbox p-3'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Sl</th>
                            <th>Category name</th>
                            <th>Details</th>
                            <th>Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>
        </Container>
    )
}

export default Categories
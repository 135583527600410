import { Paper } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../assets/logo-blue.png'
import Mobile from '../../assets/mobile.png'
import Alert from '../../components/Alert';
import { AppConfig } from '../../config/Appconfig';
import { Usercontext } from '../../config/Usercontext';

function Resetpass() {
    const { setLogin } = useContext(Usercontext)
    const navigate = useNavigate();
    const [form, setForm] = useState({
        username: '',
        passwd: ''
    })
    const [alert, setAlert] = useState({ variant: '', message: '' })

    const checkLogin = () => {
        if (form.username == '' || form.passwd == '') {
            setAlert({ variant: 'danger', message: 'Please fill required fields' })
            return
        }
        setAlert({ ...alert, message: 'Checking...', variant: 'info' })
        let url = AppConfig.LOGIN_URL;
        axios.post(url, form).then(result => {
            let resp = result.data;
            if (resp.success) {
                setAlert({ ...alert, variant: 'success', message: resp.message })
                localStorage.setItem("_login", JSON.stringify(resp.data));
                setLogin(resp.data)
                navigate('/')
            } else {
                setAlert({ ...alert, variant: 'danger', message: resp.message })
            }
        }).catch(err => console.log('login error', err))
    }
    return (
        <div className='login-page'>
            <div className='container'>
                <Paper sx={{ borderRadius: 1, overflow: 'hidden', marginTop: 10, marginBottom: 10 }}>
                    <div className='row'>
                        <div className='col-sm-6 d-none d-sm-flex justify-content-center align-items-center ' style={{ backgroundColor: '#5B2D2C' }}>
                            <div className='text-white'>
                                <img src={Mobile} alt='Logo' title='Kosoftrust Logo' width={300} />
                                <h6 className='text-center'>Account Login</h6>
                            </div>

                        </div>
                        <div className='col-sm-6'>
                            <div className='login-wrapper'>
                                <div className='d-flex mb-4 flex-column align-items-center'>
                                    <div className='login-logo'>
                                        <img src={Logo} alt='Logo' title='Workice Logo' />
                                    </div>
                                    <h6>KT Panel</h6>
                                </div>
                                <Alert variant={alert.variant} message={alert.message} />
                                <div className='card py-3 mb-2'>
                                    <div className='mb-2'>
                                        <label>Email Id</label>
                                        <input
                                            value={form.username}
                                            onChange={ev => setForm({ ...form, username: ev.target.value })}
                                            className='form-control' />
                                    </div>
                                    <div className='mb-2'>
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            value={form.passwd}
                                            onChange={ev => setForm({ ...form, passwd: ev.target.value })}
                                            className='form-control' />
                                    </div>
                                    <div className='d-grid'>
                                        <button
                                            onClick={checkLogin}
                                            className='btn btn-warning'>
                                            LOGIN</button>
                                    </div>
                                    <div className='pt-2 small' > <Link className='text-info' to={'/reset-password'}>Forgot your password</Link> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default Resetpass
import { Menu } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../assets/logo-blue.png'
import { Usercontext } from '../config/Usercontext'

function Header() {
    const { setLogin } = useContext(Usercontext)
    const navigate = useNavigate()
    const [cmenu, setCMenu] = useState('')

    const logout = () => {
        localStorage.clear();
        setLogin({ login: 0, name: 'Guest' })
        navigate('/login')
        window.location.reload()
    }

    const showMenu = () => {
        setCMenu('show')
    }

    const hideMenu = () => {
        setCMenu('show hiding')
        setTimeout(() => {
            setCMenu('')
        }, 1000)
    }

    return (
        <div className='header'>
            {/* <div className='container-fluid py-2'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <Link to={'/'}>
                            <img src={Logo} alt='Logo' title='Workice Logo' />
                        </Link>
                    </div>
                </div>
            </div> */}
            <div className='menu-outer'>
                <div className='container-fluid d-flex align-items-center justify-content-between'>
                    <Link to={'/'}>
                        <img src={Logo} alt='Logo' title='Workice Logo' />
                    </Link>
                    <Button className='d-block d-sm-none' onClick={showMenu} color="warning">
                        <Menu />
                    </Button>
                    <div className='header-menu'>
                        <ul>
                            <li>
                                <Link to={'/'}>Home</Link>
                            </li>
                            <li>
                                <Link to={'/'}>Catalog</Link>
                                <ul>
                                    <li><Link to={'/categories'}>Categories</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to={'/settings'}>Members</Link>
                                <ul>
                                    <li><Link to={'/members'}>All Members</Link></li>
                                    <li><Link to={'/applications'}>Member Applications</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to={'/reports'}>Reports</Link>
                                <ul>
                                    <li><Link to={'/reports/day-book'}>Daybook Report</Link></li>
                                    <li><Link to={'/reports/tax'}>Tax Report</Link></li>
                                    <li><Link to={'/reports/operators'}>Operator Wise Report</Link></li>
                                    <li><Link to={'/reports/profit-loss'}>Profit Loss Report</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to={'/masters'}>Master</Link>
                                <ul>
                                    <li><Link to={'/masters/utility-services'}>Utility Service Providers</Link></li>
                                    <li><Link to={'/masters/banks'}>Bank List</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to={'/payments'}>Payment</Link>
                                <ul>
                                    <li><Link to={'/payments/recent'}>Recent Payment</Link></li>
                                    <li><Link to={'/payments/history'}>Payment History</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to={'/settings'}>Settings</Link>
                                <ul>
                                    <li><Link to={'/settings/states'}>States</Link></li>
                                    <li><Link to={'/settings/districts'}>District</Link></li>
                                    <li><Link to={'/settings/blocks'}>Blocks</Link></li>
                                    <li><Link to={'/settings/villages'}>Villages</Link></li>
                                    <li><Link to={'/settings/total-seats'}>Total Seats</Link></li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link onClick={logout} to={'#'}>Logout</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={'offcanvas offcanvas-start ' + cmenu} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title  text-white" id="offcanvasExampleLabel">NAVIGATION</h5>
                    <button
                        onClick={hideMenu}
                        type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul>
                        <li>
                            <Link to={'/'}>Home</Link>
                        </li>
                        <li>
                            <Link to={'/'}>Catalog</Link>
                            <ul>
                                <li><Link to={'/categories'}>Categories</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/settings'}>Members</Link>
                            <ul>
                                <li><Link to={'/members'}>All Members</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/reports'}>Reports</Link>
                            <ul>
                                <li><Link to={'/reports/day-book'}>Daybook Report</Link></li>
                                <li><Link to={'/reports/tax'}>Tax Report</Link></li>
                                <li><Link to={'/reports/operators'}>Operator Wise Report</Link></li>
                                <li><Link to={'/reports/profit-loss'}>Profit Loss Report</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/masters'}>Master</Link>
                            <ul>
                                <li><Link to={'/masters/utility-services'}>Utility Service Providers</Link></li>
                                <li><Link to={'/masters/banks'}>Bank List</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/payments'}>Payment</Link>
                            <ul>
                                <li><Link to={'/payments/recent'}>Recent Payment</Link></li>
                                <li><Link to={'/payments/history'}>Payment History</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to={'/settings'}>Settings</Link>
                            <ul>
                                <li><Link to={'/settings/states'}>States</Link></li>
                                <li><Link to={'/settings/districts'}>District</Link></li>
                                <li><Link to={'/settings/blocks'}>Blocks</Link></li>
                                <li><Link to={'/settings/villages'}>Villages</Link></li>
                                <li><Link to={'/settings/total-seats'}>Total Seats</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Header
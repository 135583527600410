import { CheckCircle } from '@mui/icons-material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from '..'
import Loader from '../../components/Loader'
import { AppConfig } from '../../config/Appconfig'
import { Usercontext } from '../../config/Usercontext'
import { Pagination, Paper } from '@mui/material'

function Applications() {
    const { login } = useContext(Usercontext)
    const [users, setUsers] = useState([])
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [slno, setSlno] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)

    useEffect(() => {
        loadData()
    }, [page])

    const loadData = () => {
        console.log("page-" + page);
        let url = AppConfig.API_URL + 'applications'
        axios.post(url, { page: page }).then(result => {
            const resp = result.data;
            console.log(resp);
            if (resp.success) {
                setTotalPage(Math.ceil(resp.data.total / resp.data.perPage));
                setUsers(resp.data.items);
                setSlno(resp.data.slno);
                setTotalRecords(resp.data.total);
            }
            setLoader(false)
        }).catch(er => console.log('applications', er))
    }

    const details = (id) => {
        navigate('/members/application-details/' + id)
    }

    return (
        <Container>
            <div className='page-header'>
                <h5>Membership Applications : {totalRecords}  Found</h5>
            </div>
            <Paper className='mb-1 p-2 d-flex justify-content-center'>
                <Pagination count={totalPage} page={page} onChange={(e, v) => setPage(v)} variant="outlined" showFirstButton showLastButton />
            </Paper>
            <div className='cardbox p-3'>
                {loader && <Loader />}
                {!loader && <table className='table m-0'>
                    <thead>
                        <tr>
                            <th>Sl</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Application</th>
                            <th>Aadhar Number</th>
                            <th>PAN Number</th>
                            <th>Mobile no</th>
                            <th>Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, sl) => (
                            <tr key={'m' + sl}>
                                <td>{slno + sl}</td>
                                <td>{item.full_name.toUpperCase()}</td>
                                <td>{item.position.toUpperCase()}</td>
                                <td>{item.status == 1 && <span className='badge bg-success'>Verified</span>}
                                    {item.status == 0 && <span className='badge bg-warning'>Pending</span>}
                                    {item.status == 2 && <span className='badge bg-danger'>Rejected</span>}
                                </td>
                                <td>{item.aadhar_no}</td>
                                <td>{item.pan}</td>
                                <td>{item.mobile}</td>
                                <td>{item.created}</td>
                                <td>
                                    <button onClick={() => details(item.id)} className='btn btn-xs btn-primary'>Details</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>
            <Paper className='mt-1 p-2 d-flex justify-content-center'>
                <Pagination count={totalPage} page={page} onChange={(e, v) => setPage(v)} variant="outlined" showFirstButton showLastButton />
            </Paper>
        </Container>
    )
}

export default Applications
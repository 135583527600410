import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import './style/global.css'
import { Usercontext } from './config/Usercontext'
import WebRouter from './config/Routes';
import { ThemeProvider } from '@mui/material/styles';
import themes from './config/Themes'


function App() {
  const [login, setLogin] = useState({ id: 0, theme: 'light', name: 'Guest' })
  const [alert, setAlert] = useState({ variant: '', message: '' })

  useEffect(() => {
    const lg = localStorage.getItem('_login')
    if (lg !== null) {
      setLogin(JSON.parse(lg))
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={themes}>
        <Usercontext.Provider value={{ login, setLogin, alert, setAlert }}>
          <WebRouter />
        </Usercontext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;

import { Button, Container, Input, Modal, Paper, Box, Alert, CircularProgress } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppConfig } from '../../config/Appconfig';

function States() {
    const [action, setAction] = useState('list');
    const [statename, setStatename] = useState('')
    const [state_code, setState_code] = useState('')
    const [edit_id, setEdit_id] = useState(0)
    const [seats, setSeats] = useState('')
    const [openAmt, setOpenamt] = useState('')
    const [alert, setAlert] = useState({ visible: false, message: '', variant: 'error' })
    const [saving, setSaving] = useState(false)
    const [states, setStates] = useState([])
    const [file_selected, setFile_selected] = useState(null)
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        loadStates()
    }, [action])

    const loadStates = () => {
        let url = AppConfig.API_URL + 'states'
        axios.post(url, { action: 'show' }).then(result => {
            let resp = result.data;
            if (resp.success)
                setStates(resp.data)
        })
    }

    const delState = (id) => {
        if (window.confirm("are you sure to delete?")) {
            let url = AppConfig.API_URL + 'states'
            axios.post(url, { id: id, action: 'delete' }).then(result => {
                let resp = result.data;
                if (resp.success)
                    loadStates()
            })
        }
    }

    const editForm = (item) => {
        setAction({ ...action, message: '' })
        setStatename(item.state_name)
        setSeats(item.seats)
        setOpenamt(item.wallet)
        setEdit_id(item.id)
        setState_code(item.state_code)
        setAction('add')
        setAlert({ ...alert, visible: false })
    }

    const uploadNow = () => {
        if (file_selected == null) {
            window.alert('Please select file to upload')
            return;
        }
        setUploading(true)
        const form_data = new FormData();
        form_data.append("state_file", file_selected, file_selected.name)
        form_data.append("state_code", state_code)

        let url = AppConfig.API_URL + 'upload-state-data';
        axios({
            method: 'post',
            url: url,
            data: form_data,
            headers: { "Content-Type": "multipart/form-data" }
        }).then(result => {
            let resp = result.data;
            console.log(resp)
        }).finally(() => setUploading(false))
    }

    const doChange = (ev, stc) => {
        console.log(ev)
        setState_code(stc);
        setFile_selected(ev.target.files[0])
    }

    const handleSaveState = () => {
        if (statename == '') {
            setAlert({ visible: true, message: 'Enter state name', variant: 'error' })
            return;
        }
        if (seats == '') {
            setAlert({ visible: true, message: 'Enter seats', variant: 'error' })
            return
        }
        setSaving(true)
        const post = { state_name: statename, seats: seats, wallet: openAmt, action: 'save', id: edit_id, state_code: state_code }
        let url = AppConfig.API_URL + 'states'
        axios.post(url, post).then(result => {
            let resp = result.data;
            if (resp.success) {
                setAlert({ ...alert, variant: 'success', message: resp.message, visible: true })
                setStatename('')
                setSeats('')
                setOpenamt('')
            } else {
                setAlert({ ...alert, variant: 'error', message: resp.message, visible: true })
            }
        }).catch(err => console.log(err.message))
            .finally(() => setSaving(false))
    }

    if (action == 'add') {
        return (
            <Box>
                <Container maxWidth="sm">
                    <h6>Add State</h6>
                    {alert.visible && <Alert severity={alert.variant}>{alert.message}</Alert>}
                    <Paper className="p-3">
                        <label>State name</label>
                        <input
                            onChange={ev => setStatename(ev.target.value)}
                            value={statename}
                            className="form-control mb-3" placeholder='State name' />
                        <label>State code</label>
                        <input
                            value={state_code}
                            onChange={ev => setState_code(ev.target.value)}
                            className='form-control mb-3' placeholder='State code' />
                        <div className='row'>
                            <div className='col-sm-6'>
                                <label>Seats</label>
                                <input
                                    onChange={ev => setSeats(ev.target.value)}
                                    value={seats}
                                    fullWidth placeholder='Seats' className='form-control mb-3' />
                            </div>
                            <div className='col-sm-6'>
                                <label>Open Wallet Balance</label>
                                <input
                                    onChange={ev => setOpenamt(ev.target.value)}
                                    value={openAmt}
                                    fullWidth placeholder='Balance' className='form-control mb-3' />
                            </div>
                        </div>
                        <Button
                            disabled={saving}
                            onClick={handleSaveState} sx={{ mr: 1 }}>
                            {saving ? <CircularProgress className='me-2' size={15} /> : ''} SAVE
                        </Button>
                        <Button color='secondary' onClick={() => {
                            setAction('list')
                            setSaving(false)
                        }} >Cancel</Button>
                    </Paper>
                </Container>
            </Box >
        )
    }

    return (
        <>
            <div className='page-header mb-2'>
                <h6>States</h6>
                {/* <Link className='btn btn-sm btn-primary' to={'add-state'}>Add State</Link> */}
                {/* <Button size="small" onClick={handleAddState} >Add State</Button> */}
            </div>

            <div className='cardbox'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>State name (Code) </th>
                            <th>Seats</th>
                            <th>Initial Wallet</th>
                            <th>Members</th>
                            <th>Action</th>
                            <th>Import</th>
                        </tr>
                    </thead>
                    <tbody>
                        {states.length > 0 && <>
                            {states.map((item, sl) => (
                                <tr key={'stlist' + sl}>
                                    <td>{sl + 1}</td>
                                    <td>{item.state_name} ({item.state_code}) </td>
                                    <td>{item.seats}</td>
                                    <td>{item.wallet}</td>
                                    <td>
                                        <Link to={'users'} className="btn btn-xs btn-primary">10</Link>
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => editForm(item)}
                                            className="btn btn-xs btn-primary">Edit</button>
                                        <button onClick={() => delState(item.id)} className='btn ms-1 btn-danger btn-xs'>Delete</button>
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <input type="file"
                                                onChange={(ev) => doChange(ev, item.state_code)}
                                                className='form-control form-control-sm me-2' style={{ width: 200 }} />
                                            <button
                                                onClick={uploadNow}
                                                className='btn btn-xs btn-warning' >
                                                {uploading ? 'importing...' : 'import'}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </>}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default States
import React, { useContext, useEffect } from 'react'
import Header from '../components/Header'
import Alert from '../components/Alert'
import { Usercontext } from '../config/Usercontext'
import { useState } from 'react';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';

function Container(props) {
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const ls = localStorage.getItem('_login');
        if (ls == null) {
            navigate('/login')
        }
        setLoader(false)
    }, [])

    if (loader) {
        return (
            <div className='dashboard bg-white h-100' style={{ minHeight: 600 }}>
                <Loader />
            </div>
        )
    }

    return (
        <div className='dashboard'>
            <Header />
            <div className='container-fluid'>
                <div className='dashboard-container'>
                    <Alert />
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Container
import { createTheme } from "@mui/material/styles";

const themes = createTheme({
    typography: {

    },
    components: {
        MuiButton: {
            defaultProps: {
                color: 'primary',
                disableElevation: true,
                variant: "contained"
            },
            styleOverrides: {
                root: {
                    borderRadius: 2
                }
            }
        },
        MuiAlert: {
            defaultProps: {
                sx: { marginBottom: 1, borderRadius: 1 }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            }
        }
    },
    palette: {
        secondary: {
            main: '#212121'
        }
    }
})

export default themes;
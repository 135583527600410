import { Button, Paper } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'

function Accounts() {
    const [mobile, setMobile] = useState('')
    const navigate = useNavigate()

    const doSearch = () => {
        if ((mobile + '').length == 10) {
            alert('Enter mobile');
            return
        }
        navigate('/search/?q=' + mobile)
    }

    return (
        <div className='dashboard'>
            <Header />
            <div className='container-fluid'>
                <div className='py-3'>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <div className='input-group'>
                                <input
                                    onChange={ev => setMobile(ev.target.value)}
                                    placeholder='Enter mobile no'
                                    maxLength={10}
                                    className='form-control input-sm' />
                                <Button onClick={doSearch} >GO</Button>
                            </div>
                        </div>
                        <div className='col-sm-9'>
                            <div className='row g-2'>
                                <div className='col-6 col-sm-3'>
                                    <label><b>Name</b></label>
                                    <div>-</div>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <label><b>Register No</b></label>
                                    <div>-</div>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <label><b>DOI Register</b></label>
                                    <div>-</div>
                                </div>
                                <div className='col-6 col-sm-3'>
                                    <label><b>HHP Eligible</b></label>
                                    <div>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='account-menu'>
                    <ul>
                        <li><Link to={'/accounts'}>Home</Link> </li>
                        <li><Link to={'/accounts'}>Account Liablity</Link> </li>
                        <li><Link to={'/accounts'}>ATM Card</Link> </li>
                        <li><Link to={'/accounts'}>Pre-Filled Form</Link> </li>
                        <li><Link to={'/accounts'}>Service Request</Link> </li>
                        <li><Link to={'/accounts'}>General Info</Link> </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-sm-4'>
                        <Paper sx={{ p: 2 }}>
                            <div className='item-row'>
                                <div className='label-item-row'>Type</div>
                                <div className='label-item-value'>-</div>
                            </div>
                            <div className='item-row'>
                                <div className='label-item-row'>Join Date</div>
                                <div className='label-item-value'>-</div>
                            </div>
                            <div className='item-row'>
                                <div className='label-item-row'>Status</div>
                                <div className='label-item-value'>-</div>
                            </div>
                            <div className='item-row'>
                                <div className='label-item-row'>Aadhar No</div>
                                <div className='label-item-value'>-</div>
                            </div>
                            <div className='item-row'>
                                <div className='label-item-row'>RM Details</div>
                                <div className='label-item-value'>-</div>
                            </div>
                            <div className='item-row'>
                                <div className='label-item-row'>HHP Status</div>
                                <div className='label-item-value'>-</div>
                            </div>
                            <div className='item-row'>
                                <div className='label-item-row'>Last Donation</div>
                                <div className='label-item-value'>-</div>
                            </div>
                            <div className='item-row'>
                                <div className='label-item-row'>Last INR Donate</div>
                                <div className='label-item-value'>-</div>
                            </div>
                        </Paper>
                    </div>
                    <div className='col-sm-8'>
                        <div className='p-2 bg-dark text-white mb-1'>
                            Integrated Relationship Value
                        </div>
                        <div className='p-2 bg-info text-white mb-1 d-flex'>
                            <span className='me-4'>Cust Id: 1233 </span>
                            <span className='me-4'>Count of NT </span>
                            <span className='me-4'>NTE Balance</span>
                            <span className='me-4'>Unclear Balance</span>
                        </div>
                        <div className='bg-white mb-2 p-2'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Currency</th>
                                        <th>A/c Bal</th>
                                        <th>Card Bal</th>
                                        <th>Unclear Bal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>INR</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='p-2 color-orange2 text-white mb-1 d-flex'>
                            <span className='me-4'>Cust Id: 1233 </span>
                            <span className='me-4'>Count of NT </span>
                            <span className='me-4'>NTE Balance</span>
                            <span className='me-4'>Unclear Balance</span>
                        </div>
                        <div className='bg-white mb-2 p-2'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Currency</th>
                                        <th>HHP</th>
                                        <th>Green India</th>
                                        <th>Other</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>INR</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='p-2 color-primary text-white mb-1 d-flex align-items-center'>
                            <span className='me-4'>Transaction Details</span>
                            <span className='me-4'>
                                <select className='form-select form-select-sm'>
                                    <option>All Transactions</option>
                                </select>
                            </span>
                            <span className='me-4 d-flex align-items-center'>
                                <span className='me-2'>From: </span>
                                <input className='form-control form-control-sm' type={'date'} />
                            </span>
                            <span className='me-4 d-flex align-items-center'>
                                <span className='me-2'>From: </span>
                                <input className='form-control form-control-sm' type={'date'} />
                            </span>
                        </div>
                        <div className='bg-white mb-2 p-2'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>Cr/Dr</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>INR</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accounts
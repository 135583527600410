import React from 'react'
import Container from '..'
import Dashboard from '../dashboard'

function AddCategory() {
    return (
        <Container>
            <div className='page-header'>
                <h5>Category Form</h5>
            </div>
        </Container>
    )
}

export default AddCategory
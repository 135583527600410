import React from 'react'
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom'
import Accounts from '../page/accounts'
import Categories from '../page/categories'
import AddCategory from '../page/categories/add'
import Dashboard from '../page/dashboard'
import Login from '../page/login'
import Masters from '../page/masters'
import Members from '../page/members'
import MemberDetails from '../page/members/details'
import Payments from '../page/payments'
import Reports from '../page/reports'
import Resetpass from '../page/reset-password'
import Search from '../page/search'
import Settings from '../page/settings'
import Blocks from '../page/settings/blocks'
import Districts from '../page/settings/districts'
import Panchayat from '../page/settings/panchayat'
import States from '../page/settings/states'
import TotalSeats from '../page/settings/total-seats'
import Villages from '../page/settings/villages'
import Applications from '../page/members/applications'
import ApplicationDetails from '../page/members/application-details'

const router = createHashRouter([
    {
        path: "/",
        element: <Dashboard />,
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/reset-password',
        element: <Resetpass />
    },
    {
        path: '/members',
        element: <Members />
    },
    {
        path: '/applications',
        element: <Applications />
    },
    {
        path: '/categories',
        element: <Categories />,
        children: [
            {
                path: 'add',
                element: <AddCategory />
            }
        ]
    },
    {
        path: '/reports',
        element: <Reports />,
        children: [
            {
                path: 'tax',
                element: <Login />
            },
            {
                path: 'day-book',
                element: <Login />
            },
            {
                path: 'operators',
                element: <Login />
            },
            {
                path: 'profit-loss',
                element: <Login />
            }
        ]
    },
    {
        path: '/masters',
        element: <Masters />,
        children: [
            {
                path: 'utility-services'
            },
            {
                path: 'banks'
            }
        ]
    },
    {
        path: '/settings',
        element: <Settings />,
        children: [
            {
                path: 'states',
                element: <States />
            },
            {
                path: 'districts',
                element: <Districts />
            },
            {
                path: 'blocks',
                element: <Blocks />
            },
            {
                path: 'panchayat',
                element: <Panchayat />
            },
            {
                path: 'villages',
                element: <Villages />
            },
            {
                path: 'total-seats',
                element: <TotalSeats />
            }
        ]
    },
    {
        path: '/members/details/:id',
        element: <MemberDetails />
    },
    {
        path: '/members/application-details/:id',
        element: <ApplicationDetails />
    },
    {
        path: '/payments',
        element: <Payments />
    },
    {
        path: '/payments/:tab',
        element: <Payments />
    },
    {
        path: '/accounts/:mobile',
        element: <Accounts />
    },
    {
        path: '/search',
        element: <Search />
    }
])


export default function WebRouter() {
    return (
        <RouterProvider router={router} />
    )
}
